import Vue from 'vue'
// import { transferDate } from './date'

// 自义定指令 focus
Vue.directive('focus', {
    inserted(el) {
        el.focus()
    }
})

// 自义定指令 time
// Vue.directive('time', {
//     inserted(el, binding) {
//         el.innerHTML = transferDate(binding.value);
//     }
// })