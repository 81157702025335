<template>
  <div>
    <el-dialog
      class="loginDiaBox"
      :before-close="setShowData"
      :visible.sync="!!$store.state.showLoginDialog"
    >
      <div class="LoginBox">
        <loginBB :isDia="true" />
      </div>
    </el-dialog>
  </div>
</template>
<script>
import loginBB from "@/views/login/login.vue";
export default {
  data() {
    return {};
  },
  components: {
    loginBB,
  },
  computed: {},
  created() {},
  methods: {
    setShowData() {
      this.$store.commit("getshowLoginDialog", false);
    },
  },
};
</script>
<style lang='scss' scoped='scoped'>
@import "@/css/mixin.scss";
.loginDiaBox {
  /deep/.el-dialog {
    width: max-content;
  }
  /deep/.el-dialog__header {
    // display: none;
    padding: 0;
  }
  /deep/.el-dialog__body {
    padding: 0;
  }
}
.LoginBox {
}
</style>