let urlHead = "";
if (
  location.host.indexOf("8080") != -1 ||
  location.host.indexOf("8081") != -1
) {
  urlHead = "https://laveltest.rakumart.cn";
  // urlHead = "https://laravel.rakumart.es";
} else if (location.host.indexOf("192.168") != -1) {
  urlHead = "https://laravel.rakumart.es";
} else if (location.host.indexOf(".es") != -1) {
  urlHead = "https://laravel.rakumart.es";
} else {
  urlHead = "https://laveltest.rakumart.cn";
}
// if (urlHead == 'https://laveltest.rakumart.cn') {
//     alert('现在是测试接口')
// }
if (
  location.href.indexOf("rakumart.es") != -1 &&
  urlHead == "https://laveltest.rakumart.cn"
) {
  document.getElementsByTagName("body")[0].style.pointerEvents = "none";
}
export default urlHead;
