<!-- // 消息框 文本             类型
 $Mmsg('dskjhfksjhdf', 'warning / success / error / null ');调用 -->
<template>
  <div>
    <div id="fudongmessageShow"></div>
    <div class="ddddd" v-show="false">
      <!-- 普通盒子 暂无样式设计-->
      <div id="fudongmessageBoxNumo" class="messaBox">
        <div class="whiteMessage Message">
          <span class="msg">文本</span>
        </div>
      </div>
      <!-- 成功盒子 -->
      <div id="fudongmessageBoxSuccess" class="messaBox">
        <div class="greenMessage Message">
          <img
            src="https://rakumart-test.oss-cn-hangzhou.aliyuncs.com/202407/668e2bc0db719.png
"
            alt=""
          />
          <span class="msg">文本</span>
        </div>
      </div>
      <!-- 警告盒子 -->
      <div id="fudongmessageBoxWarning" class="messaBox">
        <div class="yellowMessage Message">
          <img
            src="https://rakumart-test.oss-cn-hangzhou.aliyuncs.com/202407/668f3406e11a0.svg"
            alt=""
          />
          <span class="msg">文本</span>
        </div>
      </div>
      <!-- 错误盒子 暂无样式设计 -->
      <div id="fudongmessageBoxError" class="messaBox">
        <div class="redMessage Message">
          <img
            src="https://rakumart-test.oss-cn-hangzhou.aliyuncs.com/202407/668f3406e11a0.svg"
            alt=""
          />
          <span class="msg">文本</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  components: {},
  computed: {},
  created() {},
  methods: {
    Mmsg(txt, type) {
      let fudongmessageShow = document.getElementById("fudongmessageShow");
      let fudongmessageBox = document.getElementById("fudongmessageBoxNumo");
      if (type == "success") {
        fudongmessageBox = document.getElementById("fudongmessageBoxSuccess");
      } else if (type == "warning") {
        fudongmessageBox = document.getElementById("fudongmessageBoxWarning");
      } else if (type == "error") {
        fudongmessageBox = document.getElementById("fudongmessageBoxError");
      }
      // 复制节点
      let fudongmessageBoxcopy = fudongmessageBox.cloneNode(true);

      // 修改文本
      fudongmessageBoxcopy.getElementsByClassName("msg")[0].innerText = txt;

      // 添加节点
      fudongmessageShow.appendChild(fudongmessageBoxcopy);

      // 播放显示动画
      fudongmessageBoxcopy.classList.add("showMessage");
      console.log(fudongmessageBoxcopy);
      // 删除节点
      setTimeout(() => {
        fudongmessageBoxcopy.style.opacity = 0;
        fudongmessageBoxcopy.style.transform = "translateY(-10px)";
        setTimeout(() => {
          fudongmessageShow.removeChild(fudongmessageBoxcopy);
        }, 300);
        // fudongmessageShow.removeChild(fudongmessageBoxcopy);
      }, 3000);
    },
  },
};
</script>
<style lang="scss" scoped="scoped">
.ddddd {
  position: fixed;
  top: 50%;
}

#fudongmessageShow {
  position: fixed;
  top: 75px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  // background-color: #17b26a;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: max-content;

  z-index: 11111;
}
.messaBox {
  // 显示动画用
  transition: 0.3s;
}
.Message {
  width: 800px;
  min-height: 80px;
  display: flex;
  align-items: center;
  padding: 10px 20px 10px 47px;
  margin-bottom: 20px;
  /* Shadows/shadow-xl */
  box-shadow: 0px 20px 24px -4px rgba(16, 24, 40, 0.08),
    0px 8px 8px -4px rgba(16, 24, 40, 0.03);
  border-radius: 16px;

  // 显示动画用
  transition: 0.3s;
  animation: fade 0.5s ease;
  // ------------
  img {
    margin-right: 24px;
  }
  span {
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    color: white;
  }
}

.Message.showMessage {
  transform: translateY(0px);
  opacity: 1;
}

.Message.greenMessage {
  background: var(--Success-500, #17b26a);
}

.Message.yellowMessage {
  background: var(--Success-500, #f79009);
}

.Message.redMessage {
  background: var(--Success-500, #ff0c0c);
}

.Message.whiteMessage {
  background: var(--Success-500, #ffffff);
  span {
    color: black;
  }
}
@keyframes fade {
  from {
    transform: translateY(-20px);
    opacity: 0;
  }

  to {
    transform: translateY(0px);
    opacity: 1;
  }
}
</style>
