import {
  apiAxios
} from '../axios'

import Europess from '../wangZhi'
let Europe = Europess + '/client/'
let comm_api = Europess + '/api'

export default {
  // 登录 
  logins: data => apiAxios('post', Europe + "userLogin", data),
  // 注册
  register: data => apiAxios('post', Europe + "userRegister", data),
  // 发送验证码
  sendRegisterCode: data => apiAxios('post', Europe + "sendRegisterCode", data),
  // 获取图形验证码
  codeImg: data => apiAxios('post', comm_api + "/common/codeImg", data),
  //个人中心看板统计-头部
  headCountNum: data => apiAxios('post', Europe + "user.headCount", data),
  //个人中心看板统计-底部统计
  contentCountNum: data => apiAxios('post', Europe + "user.contentCount", data),
  //RAKUMART仓库处理-顶部数据的数量统计
  warehouseOrderCountNum: data => apiAxios('post', Europe + "orderListDataNumV2", data),
  //RAKUMART仓库处理
  warehouseOrderList: data => apiAxios('post', Europe + "orderListV2", data),
  //物流与运输-顶部数量
  logisticsOrderCountNum: data => apiAxios('post', Europe + "logisticsListDataNumV2", data),
  //物流与运输-列表
  logisticsOrderList: data => apiAxios('post', Europe + "logisticsListV2", data),
  //订单列表侧边栏数量统计
  leftSidebarNum: data => apiAxios('post', Europe + "leftSidebarNumV2", data),
  // 比对验证码
  check_code: data => apiAxios('post', Europe + "checkRegisterCode", data),
  // 注册字段校验 
  checkRegisterField: data => apiAxios('post', Europe + "checkRegisterField", data),
  // 获取用户资料 
  EuropegetUserInfo: data => apiAxios('post', Europe + "userInfo", data),
  // 修改邮箱 
  userEmailSave: data => apiAxios('post', Europe + "user.emailSave", data),
  // 修改手机号 
  userMobileSave: data => apiAxios('post', Europe + "user.mobileSave", data),
  // 购物车列表
  goodsCartList: (data) => apiAxios('post', Europe + "goodsCartList", data),
  // 删除购物车商品 
  goodsCartDelete: data => apiAxios('post', Europe + "goodsCartDelete", data),
  // 修改购物车商品数量
  goodsCartEditNum: data => apiAxios('post', Europe + "goodsCartEditNum", data),
  // 商品添加购物车
  goodsToCart: data => apiAxios('post', Europe + "goodsToCart", data),
  // 核对账户信息
  forgetGetInfo: data => apiAxios('post', Europe + "forget.getInfo", data),
  // 查询购物车总条目数
  goodsToCartNum: data => apiAxios('post', Europe + "goodsToCartNum", data),
  // 找回密码（忘记密码重置
  forgetPasswordReset: data => apiAxios('post', Europe + "forget.passwordReset", data),
  // 获取各状态订单条目数 
  ordersSum: data => apiAxios('post', Europe + "ordersSum", data),

  goodsCartPostscript: data => apiAxios('post', Europe + "goodsCartPostscript", data),
  // 订单列表
  orderList: data => apiAxios('post', Europe + "orderList", data),
  // 地址列表
  useraddressList: data => apiAxios('post', Europe + "user.addressList", data),
  // 删除地址
  useraddressDelete: data => apiAxios('post', Europe + "user.addressDelete", data),
  // 编辑地址
  useraddressUpdate: data => apiAxios('post', Europe + "user.addressUpdate", data),
  // 新增地址
  useraddressAdd: data => apiAxios('post', Europe + "user.addressAdd", data),
  // 商品添加收藏 
  favoriteGoodsAdd: data => apiAxios('post', Europe + "favorite.goodsAdd", data),
  // 实时汇率
  commonExchangeRate: data => apiAxios('post', comm_api + "/common/exchangeRate", data),
  //账户优惠券展示
  couponGet: data => apiAxios('post', Europe + "couponGet", data),
  //账户优惠券使用记录
  couponRecord: data => apiAxios('post', Europe + "couponRecord", data),
  // 收藏夹标签列表
  favoriteTagList: data => apiAxios('post', Europe + "favorite.tagList", data),
  // 收藏夹商品列表
  favoriteGoodsList: data => apiAxios('post', Europe + "favorite.goodsList", data),
  // 商品收藏分配标签
  favoriteGoodsToTags: data => apiAxios('post', Europe + "favorite.goodsToTags", data),
  // 店铺收藏分配标签
  favoriteShopToTags: data => apiAxios('post', Europe + "favorite.shopToTags", data),
  // 删除标签
  favoriteTagDelete: data => apiAxios('post', Europe + "favorite.tagDelete", data),
  //添加标签
  favoriteTagAdd: data => apiAxios('post', Europe + "favorite.tagAdd", data),
  //商品收藏移除
  favoriteGoodsDelete: data => apiAxios('post', Europe + "favorite.goodsDelete", data),
  // 店铺收藏列表
  favoriteShopList: data => apiAxios('post', Europe + "favorite.shopList", data),
  // 店铺收藏新增
  favoriteShopAdd: data => apiAxios('post', Europe + "favorite.shopAdd", data),
  // 店铺收藏移除
  favoriteShopDelete: data => apiAxios('post', Europe + "favorite.shopDelete", data),
  // 店铺收藏备注信息编辑
  favoriteShopNoteSave: data => apiAxios('post', Europe + "favorite.shopNoteSave", data),
  // 商品库商品列表
  favoriteInventoryList: data => apiAxios('post', Europe + "favorite.inventoryList", data),
  // 商品库商品备注保存
  favoriteInventoryNote: data => apiAxios('post', Europe + "favorite.inventoryNote", data),
  // 商品库商品Option保存
  favoriteInventoryOption: data => apiAxios('post', Europe + "favorite.inventorySpecificationOption", data),
  //商品库商品更新数量
  favoriteInventorySpecificationNum: data => apiAxios('post', Europe + "favorite.inventorySpecificationNum", data),
  // 商品库商品移除
  favoriteInventoryDelete: data => apiAxios('post', Europe + "favorite.inventoryDelete", data),
  // 商品库商品更新
  favoriteInventoryGetGoods: data => apiAxios('post', Europe + "favorite.inventoryGetGoods", data),
  // 商品库商品批量保存
  favoriteInventoryBatchSave: data => apiAxios('post', Europe + "favorite.inventoryBatchSave", data),
  // 余额提现明细
  capitalCashOutList: data => apiAxios('post', Europe + "capital.cashOutList", data),
  // 汇款取款历史
  capitalBillList: data => apiAxios('post', Europe + "capital.billList", data),
  // 余额提现申请
  capitalCashOutSend: data => apiAxios('post', Europe + "capital.cashOutSend", data),
  // 商品库商品更新选择的属性
  favoriteInventorySpecificationChecked: data => apiAxios('post', Europe + "favorite.inventorySpecificationChecked", data),
  // 修改密码
  userPasswordSave: data => apiAxios('post', Europe + "user.passwordSave", data),
  // 验证密码
  userCheckPassword: data => apiAxios('post', Europe + "user.checkPassword", data),
  // 问题产品详情
  problemGoodsOrderDetail: data => apiAxios('post', Europe + "problemGoods.orderDetail", data),
  // 问题产品列表
  problemGoodsOrderList: data => apiAxios('post', Europe + "problemGoods.orderList", data),
  //  问题产品Table旁边的数量
  problemGoodsOrderNum: data => apiAxios('post', Europe + "problemGoods.orderNum", data),
  // 客户反馈处理意见
  problemGoodsClientDeal: data => apiAxios('post', Europe + "problemGoods.clientDeal", data),
  //  国内物流状态列表
  chinaLogisticsOrderList: data => apiAxios('post', Europe + "chinaLogistics.orderList", data),
  //  仓库列表
  storageList: data => apiAxios('post', Europe + "storage.list", data),
  // 国内物流状态Table旁边的数量
  chinaLogisticsOrderNum: data => apiAxios('post', Europe + "chinaLogistics.orderNum", data),
  // 修改姓名
  userUserNameSave: data => apiAxios('post', Europe + "user.userNameSave", data),
  // 修改账户的真实姓氏
  userUserLastNameSave: data => apiAxios('post', Europe + "user.userLastNameSave", data),
  // 修改账户的身份证号码
  userUserIdCardSave: data => apiAxios('post', Europe + "user.userIdCardSave", data),
  // 唛头列表
  userMarkList: data => apiAxios('post', Europe + "user.markList", data),
  //   修改头像
  userPortraitSave: data => apiAxios('post', Europe + "user.portraitSave", data),
  // 新增唛头
  userMarkAdd: data => apiAxios('post', Europe + "user.markAdd", data),
  // 修改唛头
  userMarkUpdate: data => apiAxios('post', Europe + "user.markUpdate", data),
  //   删除唛头
  userMarkDelete: data => apiAxios('post', Europe + "user.markDelete", data),
  // 客户仓库提出配送单前预览
  storageSendDeliverPreview: data => apiAxios('post', Europe + "storage.sendDeliverPreview", data),
  // 客户仓库提出配送单&&临时保存
  storageSendDeliver: data => apiAxios('post', Europe + "storage.sendDeliver", data),
  // 配送单列表 
  storageDeliverList: data => apiAxios('post', Europe + "storage.deliverList", data),
  // 配送单详情
  storageDeliverDetail: data => apiAxios('post', Europe + "storage.deliverDetail", data),
  // 配送单状态Table旁边的数量
  storageDeliverNum: data => apiAxios('post', Europe + "storage.deliverNum", data),
  // 临时配送单删除
  storageTampDeliverDelete: data => apiAxios('post', Europe + "storage.tampDeliverDelete", data),
  // 国际物流状态Table旁边的数量
  internationalLogisticsOrderNum: data => apiAxios('post', Europe + "internationalLogistics.orderNum", data),
  // 国际物流状态列表
  internationalLogisticsOrderList: data => apiAxios('post', Europe + "internationalLogistics.orderList", data),
  // 客户签收配送单
  internationalLogisticsClientReceive: data => apiAxios('post', Europe + "internationalLogistics.clientReceive", data),
  // 银行汇款充值余额
  capitalBankUpBalance: data => apiAxios('post', Europe + "capital.bankUpBalance", data),
  // 获取未查看的通知
  noticeUnreadNotice: data => apiAxios('post', Europe + "notice.unreadNotice", data),
  // 标记已读
  noticeReadNotice: data => apiAxios('post', Europe + "notice.readNotice", data),
  // 查看通知列表
  noticeClientNoticeList: data => apiAxios('post', Europe + "notice.clientNoticeList", data),
  // 批量删除通知
  noticeBatchDelete: data => apiAxios('post', Europe + "notice.batchDelete", data),
  // 侧边栏各类数据的条目数统计 
  sidebarNum: data => apiAxios('post', Europe + "sidebarNum", data),
  // RAKUMART仓库处理列表(配送单部分)
  porderListV2: data => apiAxios('post', Europe + "porderListV2", data),
  invoiceList: data => apiAxios('post', Europe + "user.invoice.list", data),
  messageUnread: data => apiAxios('post', Europess + "/tecent/im.messageUnread", data),
}