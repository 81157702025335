 <!-- 登录弹窗 -->
<template>
  <div class="loginBox">
    <img class="logoImg" :src="require('@/assets/login/logos.png')" alt="" />
    <div class="boxTitle">
      {{ $fanyi("是否登录") }}
    </div>
    <el-form
      :model="$parent.loginData"
      :rules="$parent.loginRules"
      ref="loginForm"
      class="demo-ruleForm"
    >
      <el-form-item prop="login_name">
        <div class="fromName">
          <!-- 用户名 -->
          <div class="placeholder">{{ $fanyi("电子邮件") }}</div>
        </div>
        <el-input
          v-model="$parent.loginData.login_name"
          :placeholder="$fanyi('电子邮件') + '/' + $fanyi('手机')"
        ></el-input>
      </el-form-item>

      <el-form-item prop="password">
        <div class="fromName">
          <!-- 密码 -->
          <div class="placeholder">{{ $fanyi("密码") }}</div>
        </div>
        <el-input
          v-model="$parent.loginData.password"
          type="password"
        ></el-input>
      </el-form-item>
    </el-form>
    <button class="loginBtn" @click="$parent.login">
      {{ $fanyi("访问") }}
    </button>
    <!-- 忘记密码 -->
    <div class="forgetPassword">
      <div class="fl">
        <el-checkbox v-model="$parent.Save_the_password"></el-checkbox>
        <span>{{ $fanyi("记住密码") }}</span>
      </div>
      <div class="fr">
        <span
          class="wangJiMiMa"
          @click="
            $store.commit('getshowLoginDialog', false);
            $fun.routerToPage('/ForgetThePassword');
          "
          >{{ $fanyi("忘记密码") }}
        </span>
      </div>
    </div>
    <!-- 注册 -->
    <div class="register">
      <div class="fl zhuCe">
        <span>{{ $fanyi("新用户?") }}</span>
        <button
          @click="
            $store.commit('getshowLoginDialog', false);
            $fun.routerToPage('/register');
          "
        >
          {{ $fanyi("现在入住") }}
        </button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  components: {},
  computed: {},
  created() {},
  methods: {},
};
</script>
<style lang='scss' scoped='scoped'>
@import "@/css/mixin.scss";
.loginBox {
  width: 480px;
  //   height: 619px;
  background: #ffffff;
  box-shadow: 0px 4px 10px 0px rgba(10, 12, 34, 0.13);
  border-radius: 6px;
  padding: 45px 50px;
  margin-right: 0;
  display: flex;
  //   justify-content: center;
  align-items: center;
  flex-direction: column;
  .logoImg {
    width: 31px;
    height: 40px;
    margin-bottom: 15px;
  }
  .boxTitle {
    font-size: 20px;
    margin-bottom: 40px;
  }
  .loginBtn {
    width: 100%;
    height: 48px;
    background: #1e2997;
    border: 1px solid #1e2997;
    border-radius: 5px;
    font-size: 14px;
    color: #ffffff;
    margin-top: 15px;
    margin-bottom: 26px;
  }
  .el-form,
  /deep/.el-form {
    width: 100%;
    .fromName {
      line-height: 1;
      margin-bottom: 11px;
    }
  }
  /deep/.el-input__inner {
    height: 48px;
  }
  /deep/ .el-form-item {
    margin-bottom: 20px;
  }
  /deep/.el-form-item__error {
  }

  .forgetPassword {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    line-height: 1;

    color: #999;
    font-size: 13px;
    margin-bottom: 53px;
    .fl {
      display: flex;
      align-items: center;
      line-height: 1;
      /deep/.el-checkbox {
        margin-right: 7px;
      }
    }
    /deep/.el-checkbox {
      margin: 0 15px 0 0px;

      .el-checkbox__input {
        height: 16px;
      }
    }
    span {
      cursor: pointer;
      vertical-align: middle;
    }
    .wangJiMiMa {
      color: #1a289d;
    }
  }
  .register {
    font-size: 12px;
    display: flex;
    justify-content: center;
    span {
      color: $subjectColor;
      white-space: nowrap;
      display: block;
      margin-bottom: 23px;
      text-align: center;
      line-height: 1;
      font-size: 13px;
    }
    button {
      width: 378px;
      height: 48px;
      border: 2px solid #1e2997;
      border-radius: 5px;
      background-color: white;
      font-size: 14px;
      font-weight: 400;
      color: #1e2997;
    }
  }
}
</style>