import Vue from "vue";
import router from "../router";
import md5 from "js-md5";
import { Message, MessageBox } from "element-ui";

let timeout = null; // 创建一个标记用来存放定时器的返回值
let canRun = true;

import store from "../store/index";
let data = {
  // 打开聊天窗口
  chat(chat) {
    if (store.state.userInfo) {
      let iTop = (window.screen.availHeight - 30 - 700) / 2;
      //获得窗口的水平位置
      let iLeft = (window.screen.availWidth - 10 - 1260) / 2;
      if (chat == 1) {
        window.open(
          "https://rakuchat1.rakumart.cn/im/dist/#/?id=" +
            store.state.userInfo.user_id,
          store.state.userInfo,
          "height=700, width=1260, left=" +
            iLeft +
            ", top=" +
            iTop +
            ", toolbar=no, menubar=no, scrollbars=yes, resizable=no, location=no, status=no"
        );
      } else {
        window.open(
          store.state.userInfo.whatsapp
            ? store.state.userInfo.whatsapp
            : "HTTPS://wa.me/+34621188939",
          "WhatsApp",
          "height=700, width=1260, left=" +
            iLeft +
            ", top=" +
            iTop +
            ", toolbar=no, menubar=no, scrollbars=yes, resizable=yes, location=no, status=no"
        );
      }
    } else {
      MessageBox(Vue.prototype.$fanyi("是否前往登录?")).then(() => {
        store.commit("getactivePage", router.history.current.fullPath);
        data.routerToPage("/login");
      });
    }
  },
  toCommodityDetails: (data) => {
    // location.href = "/ProductDetails?goods_id=" + data;

    window.open("/ProductDetails?goods_id=" + data, "_blank");
  },
  toPage: (url) => {
    location.href = url;
  },
  // 打开外部链接
  clickJs() {
    let element = document.getElementById("zsiq_float");
    // 创建事件
    var event = document.createEvent("MouseEvents");
    // 定义事件 参数： type, bubbles, cancelable
    event.initEvent("click", true, true);
    // 触发对象可以是任何元素或其他事件目标
    element.dispatchEvent(event);
  },
  setUserID() {
    let str = `rakumartsp-${(store.state.userInfo || {}).user_id}`;
    // console.log(str);
    return md5(str);
  },

  routerToPage: (url, isBlank) => {
    if (isBlank) {
      let ado = document.createElement("a");
      ado.href = url;
      ado.target = "_blank";
      document.body.appendChild(ado);
      ado.click();
      document.body.removeChild(ado);
    } else {
      location.href = url;
    }
  },
  unique: (arr) => {
    //数组去重
    return Array.from(new Set(arr));
  },
  //去除一个数组中与另一个数组中的值相同的元素
  quChu: (a, b) => {
    //数组去重
    let c = [];
    for (let i of a) {
      if (b.indexOf(i) === -1) {
        c.push(i);
      }
    }
    return c;
  },
  //   将数组转换为onum个一组
  changeArr: (oarr, onum) => {
    const arr = oarr;
    const len = arr.length;
    let result = [];
    const sliceNum = onum; // 这个值代表几个一组

    for (let i = 0; i < len / sliceNum; i++) {
      result.push(arr.slice(i * sliceNum, (i + 1) * sliceNum));
    }
    // 补足不足onum个的最后一个数组
    // for(let j = 0; j < (sliceNum - len % sliceNum); j++) {
    //     result[result.length - 1].push({})
    // }

    return result;
  },
  /**
   * 函数防抖
   * 触发事件后在n秒后执行，如果n秒内又触发事件，则重新计算时间
   */
  debounce(fn, wait = 1000) {
    let timer;
    return function() {
      let context = this;
      let args = arguments;
      if (timer) clearTimeout(timer);
      timer = setTimeout(() => {
        fn.apply(context, args);
      }, wait);
    };
  },

  /**
   * 函数节流
   * 触发事件立即执行，但在n秒内连续触发则不执行
   */
  throttle(fn, wait, options = {}) {
    let timer;
    let previous = 0;
    let throttled = function() {
      let now = +new Date();
      // remaining 不触发下一次函数的剩余时间
      if (!previous && options.leading === false) previous = now;
      let remaining = wait - (now - previous);
      if (remaining < 0) {
        if (timer) {
          clearTimeout(timer);
          timer = null;
        }
        previous = now;
        fn.apply(this, arguments);
      } else if (!timer && options.trailing !== false) {
        timer = setTimeout(() => {
          previous = options.leading === false ? 0 : new Date().getTime();
          timer = null;
          fn.apply(this, arguments);
        }, remaining);
      }
    };
    return throttled;
  },
  //   加密字符串
  toCode(str) {
    //加密字符串
    //定义密钥，36个字母和数字
    var key = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    var l = key.length; //获取密钥的长度
    var a = key.split(""); //把密钥字符串转换为字符数组
    var s = "",
      b,
      b1,
      b2,
      b3; //定义临时变量
    for (var i = 0; i < str.length; i++) {
      //遍历字符串
      b = str.charCodeAt(i); //逐个提取每个字符，并获取Unicode编码值
      b1 = b % l; //求Unicode编码值得余数
      b = (b - b1) / l; //求最大倍数
      b2 = b % l; //求最大倍数的于是
      b = (b - b2) / l; //求最大倍数
      b3 = b % l; //求最大倍数的余数
      s += a[b3] + a[b2] + a[b1]; //根据余数值映射到密钥中对应下标位置的字符
    }
    return s; //返回这些映射的字符
  },
  // 解密字符串
  fromCode(str) {
    //定义密钥，36个字母和数字
    var key = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    var l = key.length; //获取密钥的长度
    var b,
      b1,
      b2,
      b3,
      d = 0,
      s; //定义临时变量
    s = new Array(Math.floor(str.length / 3)); //计算加密字符串包含的字符数，并定义数组
    b = s.length; //获取数组的长度
    for (var i = 0; i < b; i++) {
      //以数组的长度循环次数，遍历加密字符串
      b1 = key.indexOf(str.charAt(d)); //截取周期内第一个字符串，计算在密钥中的下标值
      d++;
      b2 = key.indexOf(str.charAt(d)); //截取周期内第二个字符串，计算在密钥中的下标值
      d++;
      b3 = key.indexOf(str.charAt(d)); //截取周期内第三个字符串，计算在密钥中的下标值
      d++;
      s[i] = b1 * l * l + b2 * l + b3; //利用下标值，反推被加密字符的Unicode编码值
    }
    b = eval("String.fromCharCode(" + s.join(",") + ")"); //用fromCharCode()算出字符串
    return b; //返回被解密的字符串
  },
  // 将数字四舍五入，传入参数:数字，小数位数
  roundNumber(Num, wei) {
    return Number(Num.toFixed(wei));
  },
  // ceil(num, fix) {
  //     let result = '0'
  //     if (typeof num === 'number' && !isNaN(num)) {
  //         if (!fix) {
  //             fix = 2
  //         }
  //         // num为原数字，fix是保留的小数位数
  //         if (Number(num) && fix > 0) { // 简单的做个判断
  //             fix = +fix || 2
  //             num = num + ''
  //             if (/e/.test(num)) { // 如果是包含e字符的数字直接返回
  //                 result = num
  //             } else if (!/\./.test(num)) { // 如果没有小数点
  //                 // result = num + `.${Array(fix + 1).join('0')}`
  //             } else { // 如果有小数点
  //                 num = Math.round(num * ((fix + 1) ** 10)) / ((fix + 1) ** 10);
  //                 num = num + `${Array(fix + 1).join('0')}`
  //                 let reg = new RegExp(`-?\\d*\\.\\d{0,${fix}}`)
  //                 let floorStr = reg.exec(num)[0]
  //                 if (+floorStr >= +num) {
  //                     result = floorStr
  //                 } else {
  //                     let floorNumber = +floorStr + +`0.${Array(fix).join('0')}1`
  //                     let point = /\./.test(floorNumber + '') ? '' : '.'
  //                     let floorStr2 = floorNumber + point + `${Array(fix + 1).join('0')}`
  //                     result = reg.exec(floorStr2)[0]
  //                 }
  //             }
  //         }
  //     }
  //     return result
  // },
  // // 将数字向上取整n位
  ceil(num, precision) {
    if (!precision || precision < 0) {
      precision = 2;
    }
    if (typeof num === "number" && !isNaN(num)) {
      const factor = Math.pow(10, precision);
      return Math.ceil(num * factor) / factor;
    }
    return num;
  },
  // 跳转到指定元素，传入元素的ref或者id，如this.$refs.hfdh
  maoDian(element, orderHeight) {
    let toTop = element.offsetTop;
    if (orderHeight) {
      toTop = element.offsetTop + orderHeight;
    }
    window.scrollTo(0, toTop);
  },
  downloadIamge(imgsrc, name) {
    // 下载图片地址和图片名
    var image = new Image();
    // 解决跨域 Canvas 污染问题
    image.setAttribute("crossOrigin", "anonymous");
    image.onload = function() {
      var canvas = document.createElement("canvas");
      canvas.width = image.width;
      canvas.height = image.height;
      var context = canvas.getContext("2d");
      context.drawImage(image, 0, 0, image.width, image.height);
      var url = canvas.toDataURL("image/png"); // 得到图片的base64编码数据
      var a = document.createElement("a"); // 生成一个a元素
      var event = new MouseEvent("click"); // 创建一个单击事件
      a.download = name || "photo"; // 设置图片名称
      a.href = url; // 将生成的URL设置为a.href属性
      a.dispatchEvent(event); // 触发a的单击事件
    };
    image.src = imgsrc;
  },
  // 把价格两位小数后的文字缩小
  twoSmall(value, fontSize) {
    //console.log(value, value % 1 > 0);
    if (value % 1 > 0) {
      let arr = value.toString().split(",");
      return "<font>" + arr[0] + ".</font><font style='font-size:'" + fontSize
        ? fontSize
        : "20" + "'px'>" + arr[1] + "</font>";
    }
    return value;
  },
  // 欧元价格千分位
  EURNumSegmentation(Num) {
    if (!Num) {
      if (Num == 0) {
        return 0;
      }
      return "";
    }

    let re = /\d(?=(?:\d{3})+\b)/g;
    // let num = String(Num).replace(/./, ',')
    let num = String(Num).replace(/\./g, ",");
    num = String(num).replace(re, "$&.");
    return num;
  },
  huilvqianf(Num) {
    // let num = String(Num).replace(/./, ',')
    let num = String(Num).replace(/\./g, ",");
    return num;
  },
  // 人民币价格千分位
  RMBNumSegmentation(Num) {
    if (!Num) {
      if (Num == 0) {
        return 0;
      }
      return "";
    }
    let re = /\d(?=(?:\d{3})+\b)/g;
    // let num = String(Num).replace(/./, ',')
    let num = String(Num).replace(/\./g, ",");
    num = String(num).replace(re, "$&.");
    return num;
  },
  /* 防抖节流函数 */

  //!!!!这里一定要把变量设在外面，不然会失效！我搞了好久才搞明白！
  debounce(fn) {
    return function() {
      clearTimeout(timeout); // 每当用户输入的时候把前一个 setTimeout clear 掉
      timeout = setTimeout(() => {
        // 然后又创建一个新的 setTimeout, 这样就能保证输入字符后的 interval 间隔内如果还有字符输入的话，就不会执行 fn 函数
        fn.apply(this, arguments);
      }, 2000);
    };
  },
  throttle(fn) {
    return function() {
      if (!canRun) return;
      canRun = false;
      setTimeout(function() {
        fn.apply(this, arguments);
        canRun = true;
      }, 3000);
    };
  },
  isArray(arg) {
    if (typeof Array.isArray === "undefined") {
      return Object.prototype.toString.call(arg) === "[object Array]";
    }
    return Array.isArray(arg);
  },
  // 深度克隆
  deepClone(obj) {
    // 对常见的“非”值，直接返回原来值
    if ([null, undefined, NaN, false].includes(obj)) return obj;
    if (typeof obj !== "object" && typeof obj !== "function") {
      //原始类型直接返回
      return obj;
    }
    var o = data.isArray(obj) ? [] : {};
    for (let i in obj) {
      if (obj.hasOwnProperty(i)) {
        o[i] = typeof obj[i] === "object" ? this.deepClone(obj[i]) : obj[i];
      }
    }
    return o;
  },
  //将相同skuId的数量进行重置
  mergeArrayValue(arr) {
    let end = [];
    end.push(arr[0]);
    if (arr.length > 0) {
      for (let i = 1; i < arr.length; i++) {
        arr[i].num = Number(arr[i].num);
        let j = 0;
        for (; j < end.length; j++) {
          // 6. 判断两个数组中的id是否相同
          if (arr[i].skuId === end[j].skuId) {
            // 7.相等就将我们比较的对象 `arr[i]` 存储到  相同id对象的list 中
            end[j].num = arr[i].num;
            // *** 8.这里要理解，我们每次遍历，其实都是处理  `arr[i]` ，而上面的逻辑已经处理了，所以终止后续的循环 break;
            break;
          }
        }
        if (j === end.length) {
          end.push(arr[i]);
        }
      }
    }
    return end;
  },
  // 将每个单词首字母转换成大写
  trunUpperCase(v) {
    if (v)
      return v
        .trim()
        .toLowerCase()
        .replace(/( |^)[a-z]/g, (L) => L.toUpperCase());
  },
  //西班牙时间
  timeE(v) {
    if (v)
      return (
        v.substr(8, 2) +
        "-" +
        v.substr(5, 2) +
        "-" +
        v.substr(0, 4) +
        " " +
        v.substr(10)
      );
  },
  // js模拟点击
  emulateMouseClick(element) {
    // 创建事件
    var event = document.createEvent("MouseEvents");
    // 定义事件 参数： type, bubbles, cancelable
    event.initEvent("click", true, true);
    // 触发对象可以是任何元素或其他事件目标
    element.dispatchEvent(event);
  },
  // 关闭页面
  exitOperation() {
    alert("操作id与token不匹配,将关闭此页面");
    // localStorage.removeItem("user_token");
    window.close();
    setTimeout(() => {
      //   localStorage.removeItem("user_token");
      window.close();
    }, 5000);
  },
};
export default data;
